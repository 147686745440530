<template>
    <div>
        <van-nav-bar title="推广中心" right-text="推广码" left-text="" left-arrow @click-left="url('/')"
            @click-right="url('/sale_qr')"></van-nav-bar>

        <!-- 钱包 -->
        <div class="card">
            <div class="card-name" @click="url('/cert')">
                {{info.sale_cert_name!=='0'?info.sale_cert_name:'未认证'}}
                <img src="@/static/image/cert/state0.png" v-if="info.sale_cert_state == 0">
                <img src="@/static/image/cert/state1.png" v-if="info.sale_cert_state == 1">
                <img src="@/static/image/cert/state2.png" v-if="info.sale_cert_state == 2">
            </div>
            <div class="card-money">
                账户余额 <br>
                <div class="card-money-text"><span>￥</span>{{info.sale_balance.toFixed(2)}}</div>
            </div>
            <div class="card-money2">
                待到账：<span>￥{{info.sale_money_no.toFixed(2)}}元</span>，累计到账金额：<span>￥{{info.sale_withdraw_money.toFixed(2)}}元</span>。
            </div>
            <div class="card-button" @click="url('/balance')">提现</div>
        </div>



        <!-- 员工任务 -->
        <div class="company-count" v-if="info.bumen_id">
            <div class="title">{{getYear}}年崆峒山年卡个人任务统计</div>
            <van-row>
                <van-col span="6">
                    <div class="count">
                        <div>总任务数</div>
                        <p>{{info.task_count.total_task}}张</p>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="count">
                        <div>个人办理</div>
                        <p>{{info.task_count.person}}张</p>
                    </div>
                </van-col>
                <van-col span="6" @click="url('/company')">
                    <div class="count">
                        <div>企业办理</div>
                        <p>{{info.task_count.company}}张</p>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="count">
                        <div>剩余任务</div>
                        <p>{{info.task_count.surplus}}张</p>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="count" @click="url('/sale_child')">
                        <div>下线完成</div>
                        <p>{{info.task_count.child_number}}张</p>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="count" @click="url('/sale_child')">
                        <div>下线数量</div>
                        <p>{{info.task_count.child_people}}人</p>
                    </div>
                </van-col>

                <van-col span="6">
                    <div class="count" @click="url('/sale_rank')">
                        <p style="padding-top: 15px;margin-top: 0;">部门排名</p>
                    </div>
                </van-col>

                <van-col span="6">
                    <div class="count" @click="url('/sale_child')">
                        <p style="padding-top: 15px;margin-top: 0;">下级代理</p>
                    </div>
                </van-col>

            </van-row>
        </div>

        <!-- 默认统计 -->
        <div class="company-count">
            <div class="title">
                累计办理人数
                <span class="handle" @click="show_content = true">《年卡佣金说明》</span>
            </div>
            <van-row>
                <van-col span="6" @click="set_state(-1)">
                    <div class="count">
                        <div>总推广数</div>
                        <p>{{info.handle_all}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(1)">
                    <div class="count">
                        <div>待支付</div>
                        <p>{{info.handle_1}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(4)">
                    <div class="count">
                        <div>审核不过</div>
                        <p>{{info.handle_4}}人</p>
                    </div>
                </van-col>
                <van-col span="6" @click="set_state(8)">
                    <div class="count">
                        <div>激活成功</div>
                        <p>{{info.handle_8}}人</p>
                    </div>
                </van-col>
                
                <van-col span="6">
                    <div class="count" @click="url('/sale_withdraw')">
                        <p style="padding-top: 15px;margin-top: 0;">提现记录</p>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="count" @click="url('/sale_view')">
                        <p style="padding-top: 15px;margin-top: 0;">浏览记录</p>
                    </div>
                </van-col>
            </van-row>
        </div>


        <!--佣金说明-->
        <van-action-sheet v-model="show_content" title="佣金说明" :round="true">
            <div class="person-demand" v-html="info.content"></div>
        </van-action-sheet>


        <div class="top">
            <div class="title2">
                <div class="title-text">
                    推广列表
                    <span v-if="state==-1">(全部列表)</span>
                    <span v-if="state==0">(待支付)</span>
                    <span v-if="state==4">(审核不过)</span>
                    <span v-if="state==8">(结算到账)</span>
                    <span v-if="state==3">(已结算)</span>
                    <span v-if="state==2">(待结算)</span>
                    <div class="title-text">用户信息</div>
                </div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>

            <van-list v-model="load" :finished="finish" finished-text="没有更多了" @load="get_list(state)">
                <van-cell-group class="group-user-list">
                    <!-- 确认绑定 -->
                    <van-cell :title="'我的上级：'+info.from_wxuser.nickname" v-if="info.sale_from_id && info.id !== 2901"
                        :icon="info.from_wxuser.headimgurl" :label="info.sale_from_state==0?'点击确认绑定为下线关系':'点击解除下线关系！'"
                        style="color: blue!important;" @click="bind">

                        <template #default>
                            <van-tag type="primary" v-if="info.sale_from_state==1">已绑定</van-tag>
                            <van-tag type="danger" v-else>待确认</van-tag>
                        </template>
                    </van-cell>


                    <van-cell center v-for="(item,index) in list" :key="index" is-link
                        @click="url('/sale_detail?id='+item.id)">
                        <template #icon>
                            <van-image width="35px" height="50px" fit="cover" :src="item.face_img + '!thumbnail'">
                            </van-image>
                        </template>
                        <template #title>
                            <div style="margin-left: 10px;line-height: 20px;">
                                {{item.name}}
                                <van-tag>{{item.cate_name}}</van-tag> <br>
                                佣金：￥{{item.money}}
                            </div>
                        </template>
                        <template #label>
                            <div style="margin-left: 10px;">
                                {{item.create_time}}
                            </div>
                        </template>
                        <template #default>
                            <van-tag type="primary" :color="config.main_color">{{item.state}}</van-tag>
                        </template>
                    </van-cell>

                    <div v-if="list.length == 0">
                        <van-empty description="列表为空" />
                    </div>
                </van-cell-group>
            </van-list>
        </div>


    </div>
</template>

<script>
    import { oauth } from "@/static/js/oauth";
    import {
        sale_info,
        sale_user_list,
        sale_child_true
    } from '@/api/sale.js';
    import {
        Dialog
    } from 'vant';
    export default {
        name: 'sale',
        data() {
            return {
                load: false,
                info: {},
                show_content: false,
                list: [], //用户列表
                finish: false,
                state: -1, //状态，默认全部
            }
        },
        mounted() {
            oauth();
            this.get_sale();
        },
        computed: {
            getYear() {
                var myDate = new Date();
                return myDate.getFullYear();
            },
        },
        methods: {
            bind() {
                if (this.info.sale_from_state == 1) {
                    var text = '您确认要解绑该用户吗？';
                    var state = 0;
                } else {
                    var text = '您确定要成为该用户下级代理吗？';
                    var state = 1;
                }

                var that = this;
                Dialog.confirm({
                    title: '操作提示',
                    message: text,
                }).then(function (res) {
                    sale_child_true({
                        sale_from_state: state,
                        sale_id: that.info.id
                    }).then(res2 => {
                        Dialog.alert({
                            message: res2.msg,
                        }).then(function () {
                            that.get_sale();
                        });
                    });
                });
            },
            //滚动条触发事件
            scrollEvent(e) {
                if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight) {
                    if (!that.finish) {
                        that.get_list(that.state);
                    }
                }
            },


            //获取代理信息
            get_sale() {
                sale_info({}).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        this.get_list(-1);
                    }
                });
            },

            set_state(state) {
                this.list = [];
                this.finish = false;
                this.state = state;
                this.get_list(state);
            },

            //获取办理列表
            get_list(state) {
                var data = {
                    state: state, //状态
                    start: this.list.length,
                    count: 20,
                };
                this.load = true;
                sale_user_list(data).then(res => {
                    this.load = false;
                    if (res.code == 1) {
                        this.list = this.list.concat(res.data.info);
                        if (res.data.count < 20) {
                            this.finish = true; //加载完成
                        }
                    }
                });
            },

            url(path) {
                if (path.indexOf("http") != -1) {
                    window.location.href = path;
                } else {
                    if (path == this.$store.getters.unionid) {
                        this.$router.back();
                    } else {
                        this.$router.push(path);
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .van-cell__title {
        flex: 2;
    }

    .van-cell__value {
        width: 80px;
    }

    .title {
        font-family: 'FZCS';
        color: var(--main-color);
        margin-bottom: 10px;
    }

    .company-count {
        text-align: center;
        margin: 15px;
        background: #fff;
        border-radius: 10px;
        padding: 15px;
    }

    .company-count .title {
        text-align: left !important;
        padding-left: 5px !important;
    }


    .company-count .van-col {
        padding: 5px;

    }


    .company-count .count {
        height: 55px;
        border-radius: 5px;
        background: var(--main-color);
    }


    .company-count .count div {
        color: #fff;
        font-size: 12px;
        padding-top: 5px;
    }


    .company-count .count p {
        color: #fff;
        font-size: 16px;
        margin-top: 5px;
    }


    .van-icon__image {
        height: 50px;
        width: 25px;
    }

    .van-cell__left-icon,
    .van-cell__right-icon {
        height: auto;
    }

    .handle {
        float: right;
    }

    /deep/ .van-nav-bar__title,
    /deep/ .van-nav-bar__text {
        color: var(--main-color) !important;
    }

    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .title2 {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    /deep/ .van-nav-bar__title {
        color: var(--main-color);
        font-family: 'FZCS';
    }

    /deep/ .van-nav-bar__left i {
        color: var(--main-color) !important;
    }
    .person-demand{
        padding: 15px;
    }
    .card{
        background: #fff;
        margin: 15px;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--main-color);
        position: relative;
    }
    .card-money{
        font-weight: 400;
        color: #aaa;
    }
    .card-money-text{
        color: var(--main-color);
        margin-bottom: 10px;
        font-weight: 900;
        font-size: 24px;
    }
    .card-money span{
        font-size: 12px;
    }
    .card-money2{
        color: #aaa;
        font-size: 14px;
        margin-top: 10px;
    }
    .card-button{
        position: absolute;
        right: 10px;
        top: 30px;
        background: var(--main-color);
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .card-money2 span{
        color: #333;
        font-weight: 700;
    }
    .card-name{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .card-name img{
        height: 16px;
        margin-left: 10px;
        border-radius: 3px;
        overflow: hidden;
    }
</style>