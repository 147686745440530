import request from '@/utils/request'
export function sale_info(data) {
    return request({url: 'sale_info',data});
}
export function sale_user_list(data) {
    return request({url: 'sale_user_list',data});
}
export function sale_detail(data) {
    return request({url: 'sale_detail',data});
}
export function sale_qr(data) {
    return request({url: 'sale_qr',data});
}
export function sale_withdraw_detail(data) {
    return request({url: 'sale_withdraw_detail',data});
}
export function sale_withdraw_list(data) {
    return request({url: 'sale_withdraw_list',data});
}
export function sale_child(data) {
    return request({url: 'sale_child',data});
}
export function sale_child_bind(data) {
    return request({url: 'sale_child_bind',data});
}
export function sale_child_unbind(data) {
    return request({url: 'sale_child_unbind',data});
}
export function sale_child_true(data) {
    return request({url: 'sale_child_true',data});
}
export function sale_rank(data) {
    return request({url: 'sale_rank',data});
}
export function sale_view(data) {
    return request({url: 'sale_view',data});
}
export function sale_cert_submit(data) {
    return request({url: 'sale_cert_submit',data});
}
export function sale_withdraw_submit(data) {
    return request({url: 'sale_withdraw_submit',data});
}